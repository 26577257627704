import { useCallback, useEffect, useState } from "react";
import TableView from "../components/TableView";
import ProductcronjobsTableAction from "../generatedTableActions/ProductcronjobsTableAction";
import Button from "../components/Button";
import Badge from "../components/Badge";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCurrentPage,
  setPerPage,
  setTotalPage,
  setLoading,
  setProductcronjobs,
  setSearchTerm,
} from "../generatedSlice/productcronjobsSlice";
import useSearchApi from "../customHooks/useSearchApi";
import { getProductcronjobs } from "../generatedRequest/productcronjobs";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import ProductcronjobsList from "../generatedTableList/ProductcronjobsList";
import { TTableProps } from "../types/TableView";
import TableLoader from "../utils/Loaders/tableLoader";
import { useNavigate } from "react-router-dom";
import { ThandleAfterSearchData } from "../types/Module/Attribute/AttributePage";
import { useAuth } from "../app/modules/auth";

const columns = [
  {
    name: "name",
    renderHeader: (rows: any) => (
      <>
        <b>Name</b>
      </>
    ),
    renderCell: (row: any) => <>{row.cronJobName}</>,
  },

  {
    name: "status",
    renderHeader: (rows: any) => (
      <>
        <b>Status</b>
      </>
    ),
    renderCell: (row: any) => (
      <Badge
        variant={row.status ? "success" : "danger"}
        className="fs-7 fw-bolder"
      >
        {row.status ? "active" : "Inactive"}
      </Badge>
    ),
  },
  {
    name: "purpose",
    renderHeader: (rows: any) => (
      <>
        <b>Purpose</b>
      </>
    ),
    renderCell: (row: any) => <>{row.cronJobPurpose}</>,
  },
  {
    name: "count",
    renderHeader: (rows: any) => (
      <>
        <b>Product Count(per-cycle)</b>
      </>
    ),
    renderCell: (row: any) => <>{row.count}</>,
  },
  {
    name: "count",
    renderHeader: (rows: any) => (
      <>
        <b>Query term</b>
      </>
    ),
    renderCell: (row: any) => <>{row.searchString}</>,
  },
  {
    name: "isrunning",
    renderHeader: (rows: any) => (
      <>
        <b>Currently Running</b>
      </>
    ),
    renderCell: (row: any) => <>{row.isRunning ? "Running" : "Not Running"}</>,
  },
  {
    name: "Actions",
    renderHeader: (rows: any) => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (row: any) => (
      <ProductcronjobsTableAction productcronjob={row} />
    ),
  },
];

const ProductcronjobsPage = () => {
  const dispatch = useDispatch();
  const [initload, setInitload] = useState(true);
  const { currentUser } = useAuth();
  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    loading,
    sortBy,
    sortColumn,
    rerender1,
  } = useSelector((state: RootState) => state.productcronjobs);

  useEffect(() => {
    const fn = async () => {
      const searchQuery = `page=${currentPage}&limit=${perPage}&search=${searchTerm}`;
      dispatch(setLoading(true));
      try {
        const response = await getProductcronjobs(searchQuery);
        if (response.data.status) {
          dispatch(setProductcronjobs(response.data.data.data));
          dispatch(
            setTotalPage(Math.ceil(response.data.data.totalCount / perPage))
          );
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        dispatch(setLoading(false));
        setInitload(false);
      }
    };
    fn();
  }, [rerender1, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(""));
    };
  }, []);

  function handleAfterSearch(
    isSuceess: boolean,
    data: ThandleAfterSearchData,
    value: number
  ) {
    if (isSuceess) {
      dispatch(setProductcronjobs(data.data.data.data));
      dispatch(setTotalPage(Math.ceil(data.data.data.totalCount / value)));
    }
  }
  const { doSearch, searchLoading } = useSearchApi(
    getProductcronjobs,
    handleAfterSearch
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `page=${value}&limit=${perPage}&search=${searchTerm}`;
      doSearch(searchQuery, perPage);
    },
    [dispatch, perPage, searchTerm, doSearch]
  );

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `page=${currentPage}&limit=${value}&search=${searchTerm}`;
      doSearch(searchQuery, value);
    },
    [dispatch, currentPage, searchTerm, doSearch]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `page=${"1"}&limit=${perPage}&search=${value}`;
      doSearch(searchQuery, perPage);
    },
    [perPage, dispatch, doSearch]
  );

  const configuration: TTableProps = {
    columns: columns,
    rows: data,
    showPagination: true,
    showSearch: true,
    showPerPage: true,
    paginationData: {
      perPage: perPage,
      currentPage: currentPage,
      totalPage: totalPage,
    },
    onPageChange: handlePageChange,
    onPerPageChange: handlePerPageChange,
    handleSearchParams: handleSearchParams,
    searchPlaceholder: "Search Productcronjobss",
    searchLoading: searchLoading,
    showFilter: false,
  };

  const navigate = useNavigate();

  if (loading && initload) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h2>Productcronjobs</h2>
        {/* {currentUser?.isAdmin ? (
          <Button
            variant="primary"
            size="medium"
            handleButtonClick={() => navigate("add")}
          >
            Add Productcronjobs
          </Button>
        ) : null} */}
      </div>
      <ProductcronjobsList {...configuration} />
    </>
  );
};

export default ProductcronjobsPage;
