import React from "react";
import Brands from "../brands.json";
import InputField from "../components/Input";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";

const style = {
  multiselectContainer: {
    minheight: "44px",
    border: "1px solid var(--bs-gray-100)",

    // styles for the multiselect container
  },
  searchBox: {
    minheight: "44px",
    fontSize: "1.1rem",
    fontWeight: "500",

    // styles for the search wrapper
  },
};

type TProductcronjobsFormProp = {
  errors: any;
  isSubmitting: boolean;
  touched: any;
  isEdit: boolean;
  getFieldProps: Function;
  setFieldValue: Function;
  values: any;
  isloading: boolean;
};

const ProductcronjobsForm = ({
  errors,
  isSubmitting,
  touched,
  isEdit,
  isloading,
  getFieldProps,
  setFieldValue,
  values,
}: TProductcronjobsFormProp) => {
  const navigate = useNavigate();

  return (
    <div className="container ms-1">
      <div className="row">
        <div className="col-md-8">
          <div className="card mb-5 mb-xl-10">
            <div className="card-header border-1 cursor-pointer">
              <div className="card-title m-0">
                <div
                  role={"button"}
                  className="btn btn-sm btn-light me-5"
                  onClick={() => navigate("/productcronjobs")}
                >
                  <i className="fa-solid fa-arrow-left fs-2"></i>
                </div>
                <h3 className="fw-bolder m-0">
                  {isEdit ? "Edit" : "Add"} CRON-JOB
                </h3>
              </div>
            </div>
            <div className="card-body p-9">
              <div className="row mb-6">
                <label className="col-lg-2 col-form-label required fw-bold fs-6">
                  Name
                </label>
                <div className="col-lg-10">
                  <input
                    disabled={true}
                    placeholder="Cron Job Name"
                    {...getFieldProps("cronJobName")}
                    className={clsx("form-control bg-transparent", {
                      "is-invalid": touched?.cronJobName && errors?.cronJobName,
                    })}
                    type="text"
                  />
                  {touched?.cronJobName && errors?.cronJobName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.cronJobName}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-2 col-form-label required fw-bold fs-7">
                  Purpose
                </label>
                <div className="col-lg-10">
                  <input
                    disabled={true}
                    placeholder="cronJobPurpose"
                    {...getFieldProps("cronJobPurpose")}
                    className={clsx("form-control bg-transparent", {
                      "is-invalid":
                        touched?.cronJobPurpose && errors?.cronJobPurpose,
                    })}
                    type="text"
                  />
                  {touched?.cronJobPurpose && errors?.cronJobPurpose && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.cronJobPurpose}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-2 col-form-label required fw-bold fs-6">
                  Count
                </label>
                <div className="col-lg-10">
                  <input
                    disabled={isloading}
                    placeholder="Number of Products to fetch via cron job"
                    {...getFieldProps("count")}
                    className={clsx("form-control bg-transparent", {
                      "is-invalid": touched?.count && errors?.count,
                    })}
                    type="text"
                  />
                  {touched?.count && errors?.count && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.count}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-2 col-form-label required fw-bold fs-7">
                  Search Term
                </label>
                <div className="col-lg-10">
                  <input
                    disabled={isloading}
                    placeholder="Search Term For Cron job"
                    {...getFieldProps("searchString")}
                    className={clsx("form-control bg-transparent", {
                      "is-invalid":
                        touched?.searchString && errors?.searchString,
                    })}
                    type="text"
                  />
                  {touched?.searchString && errors?.searchString && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.searchString}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-2 col-form-label fw-bold fs-7">
                  Brand
                </label>
                <div className="col-lg-10">
                  <Multiselect
                    style={style}
                    options={
                      Brands?.categories?.filter(
                        (category: any) =>
                          category?.purpose === values?.cronJobPurpose
                      )?.[0]?.brands
                    }
                    onSelect={async (e) => {
                      console.log(e);
                      await setFieldValue("brand", e?.[0]?.brandName);
                      await setFieldValue(
                        "searchString",
                        e?.[0]?.brandName + " " + values?.searchString
                      );
                    }}
                    onRemove={async (e) => {
                      let str = values?.searchString;
                      let brandToRemove = values?.brand;
                      let regex = new RegExp(brandToRemove + "\\s*", "gi");
                      let result = str.replace(regex, "");
                      await setFieldValue("searchString", result);
                      await setFieldValue("brand", "");
                    }}
                    displayValue="brandName"
                    selectedValues={Brands?.categories
                      ?.filter(
                        (category: any) =>
                          category?.purpose === values?.cronJobPurpose
                      )?.[0]
                      ?.brands?.filter(
                        (brand) => brand.brandName === values?.brand
                      )}
                    selectionLimit={1}
                    disable={isloading || !values?.searchString}
                    placeholder="Search Brand from list After Search Term"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductcronjobsForm;
