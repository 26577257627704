import React from "react";
import ProductcronjobsFormModal from "../../generatedFormModal/ProductcronjobsFormModal";

const initialValues = { name: "" };

const AddProductcronjobs = ({ currentRecord }: { currentRecord?: any }) => {
  return (
    <div>
      <ProductcronjobsFormModal isEdit={false} />
    </div>
  );
};

export default AddProductcronjobs;
