import SettingRoutes from "../../generatedModuleRoutes/SettingRoutes";
import PermissionRoutes from "../../generatedModuleRoutes/PermissionRoutes";
import AttributeRoutes from "../../generatedModuleRoutes/AttributeRoutes";
import SubCategoryRoutes from '../../generatedModuleRoutes/SubCategoryRoutes'
import AccessoryRoutes from '../../generatedModuleRoutes/AccessoryRoutes'
import CategoryRoutes from '../../generatedModuleRoutes/CategoryRoutes'
import ProductRoutes from '../../generatedModuleRoutes/ProductRoutes'
import QuizRoutes from '../../generatedModuleRoutes/QuizRoutes'
import RetailerRoutes from '../../generatedModuleRoutes/RetailerRoutes'

import LeadRoutes from '../../generatedModuleRoutes/LeadRoutes'
import MappingRoutes from '../../generatedModuleRoutes/MappingRoutes'
import ScrappingRoutes from '../../generatedModuleRoutes/ScrappingRoutes'
import ProductcronjobsRoutes from '../../generatedModuleRoutes/ProductcronjobsRoutes'
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import UserList from "../pages/User Management/UserManagement";
import { UserManagementWrapper } from "../pages/userManagement/UserManagementWrapper";
import ComponentDemo from "../pages/component-demo";
import { AddUser } from "../pages/add/add";
import { Edit } from "../pages/edit/edituser";
import LanguagesPage from "../pages/Language/LanguagesList";
import TranslationsPage from "../pages/Translations/TranslationsList";
import RegionsRoutes from "../../generatedModuleRoutes/RegionsRoutes";
import UnitsRoutes from "../../generatedModuleRoutes/UnitsRoutes";
import JatinRoutes from "../../generatedModuleRoutes/JatinRoutes";
import TestDownload from "../../generatedForms/testdownload";
import FrontendRoutes from "../../generatedModuleRoutes/FrontendRoutes";
import CustomStepper from "../../components/CustomStepper";
import HelpRoutes from "../../generatedModuleRoutes/HelpRoutes";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}

        









<Route path="productcronjobs/*" element={<ProductcronjobsRoutes/>} />
<Route path="helps/*" element={<HelpRoutes/>} />
<Route path="scrappings/*" element={<ScrappingRoutes/>} />
<Route path="mappings/*" element={<MappingRoutes/>} />
<Route path="lead/*" element={<LeadRoutes/>} />
<Route path="retailer/*" element={<RetailerRoutes/>} />
<Route path="quiz/*" element={<QuizRoutes/>} />
<Route path="product/*" element={<ProductRoutes/>} />
<Route path="category/*" element={<CategoryRoutes/>} />
<Route path="accessory/*" element={<AccessoryRoutes/>} />
<Route path="subcategory/*" element={<SubCategoryRoutes/>} />
<Route path="attribute/*" element={<AttributeRoutes />} />
        <Route path="Permission/*" element={<PermissionRoutes />} />
        <Route path="setting/*" element={<SettingRoutes />} />
        <Route path="auth/*" element={<Navigate to="/accessory" />} />
        {/* Pages */}
        <Route path="users" element={<UserList />} />
        <Route path="translations" element={<TranslationsPage />} />
        <Route path="languages" element={<LanguagesPage />} />
        <Route path="dashboard/*" element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route path="demo/*" element={<ComponentDemo />} />
        <Route path="profile/*" element={<ComponentDemo />} />
        <Route path="usermanagement/*" element={<UserManagementWrapper />} />
        <Route path="adduser/*" element={<AddUser />} />
        <Route path="edituser/*" element={<Edit />} />
        <Route path="region/*" element={<RegionsRoutes />} />
        <Route path="unit/*" element={<UnitsRoutes />} />
        <Route path="jatin/*" element={<JatinRoutes />} />
        <Route path="profile/*" element={<ComponentDemo />} />
        <Route path="frontend-demo/*" element={<FrontendRoutes />} />
        <Route path="downloadtest/*" element={<TestDownload />} />
        <Route path="/stepper" element={<CustomStepper />} />
        {/* Page Not Found */}

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
