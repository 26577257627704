import productcronjobsReducer from '../generatedSlice/productcronjobsSlice'
import ScrappingReducer from '../generatedSlice/ScrappingSlice'
import mappingReducer from '../generatedSlice/mappingSlice'
import leadReducer from '../generatedSlice/leadSlice'
import retatilerDataReducer from '../generatedSlice/retatilerDataSlice'
import retailerReducer from '../generatedSlice/retailerSlice'
import quizReducer from '../generatedSlice/quizSlice'
import productReducer from '../generatedSlice/productSlice'
import categoryReducer from '../generatedSlice/categorySlice'
import settingReducer from "../generatedSlice/settingSlice";
import PermissionReducer from "../generatedSlice/PermissionSlice";
import attributeReducer from "../generatedSlice/attributeSlice";
import subcategoryReducer from "../generatedSlice/subcategorySlice";
import accessoryReducer from "../generatedSlice/accessorySlice";
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../redux/slices/userSlice";
import translationReducer from "../redux/slices/translationSlice";
import regionReducer from "../generatedSlice/regionSlice";
import unitReducer from "../generatedSlice/unitSlice";
import nameReducer from "../generatedSlice/nameSlice";
import jatinReducer from "../generatedSlice/jatinSlice";
import languageReducer from "../redux/slices/languageSlice";
import helpReducer from  "../generatedSlice/helpSlice"

export const store = configureStore({
  reducer: {
productcronjobs:productcronjobsReducer,

help:helpReducer,    
Scrapping:ScrappingReducer,

mapping:mappingReducer,

lead:leadReducer,

retatilerData:retatilerDataReducer,

retailer:retailerReducer,

quiz:quizReducer,

product:productReducer,

category:categoryReducer,

    accessory: accessoryReducer,

    subcategory: subcategoryReducer,

    attribute: attributeReducer,

    Permission: PermissionReducer,

    setting: settingReducer,

    user: userReducer,
    translation: translationReducer,
    region: regionReducer,
    unit: unitReducer,
    name: nameReducer,
    jatin: jatinReducer,
    language: languageReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
