import React, { useCallback, useEffect, useState } from "react";
import { Formik, useFormik } from "formik";
import { userSchema } from "../../../utils/validationSchema/userSchema";
import clsx from "clsx";
import Select from "../../../components/CustomSelect";
import { ToastContainer, toast } from "react-toastify";
import { TOptionType } from "../../../types/CustomSelect";
import { deleteRole, getUserData } from "../../modules/auth/core/_requests";
import Button from "../../../components/Button";
import { useSearchParams } from "react-router-dom";
import RoleFormModal from "./RoleFormModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useDelete } from "../../../customHooks/useDelete/useDelete";
import { setRoleRerender } from "../../../redux/slices/userSlice";
import ConfirmationPrompt from "../../../components/ConfirmationPrompt";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import Multiselect from "multiselect-react-dropdown";
import CustomArrow from "../../../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../../../utils/CustomStyleComponent/CrossButtonComponent";
import CustomTooltip from "../../../utils/CustomStyleComponent/TooltipComponent";

interface OverviewProps {
  isEdit?: boolean;
  isLoading: boolean;
  handleSubmit: Function;
  message: string;
  values: any;
  touched: any;
  errors: any;
  getFieldProps: any;
  setFieldValue: any;
  roles: any;
  roleData: any;
  allRegions: any;
  setFieldTouched: any;
  isSubmitting: boolean;
  allRetailers: any;
}

const Overview = ({
  values,
  touched,
  errors,
  getFieldProps,
  setFieldValue,
  isEdit,
  isLoading,
  handleSubmit,
  message,
  roles,
  roleData,
  allRegions,
  setFieldTouched,
  isSubmitting,
  allRetailers,
}: OverviewProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [editRole, setEditRole] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(values?.role?.id);
  const { roleRender } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const OptionsRole: TOptionType<string>[] = [
    { value: "guest", label: "Guest" },
    { value: "marketing", label: "Marketing" },
    { value: "sales", label: "Sales" },
    { value: "e-commerce", label: "E-commerce" },
  ];
  const [optionRole, setOptionRole] = useState<TOptionType<string>>({
    value: "guest",
    label: "Guest",
  });
  const handleAddRole = () => {
    setOpenModal(true);
    setEditRole(false);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleEditRole = () => {
    setOpenModal(true);
    setEditRole(true);
    setSelectedRoleId(values.role.id);
  };

  const handleAfterDelete = useCallback(
    (flag: boolean, response: any) => {
      if (flag) {
        toast.success("Role has been deleted");
        dispatch(setRoleRerender(!roleRender));
        // do whatever you want with response
      } else {
        toast.error("error");
      }
    },
    [roleRender, dispatch]
  );
  const { onDelete, deleteRecord, showPrompt, onCancel, loading } = useDelete(
    deleteRole,
    true,
    handleAfterDelete
  );

  const style = {
    multiselectContainer: {
      minheight: "44px",
      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontSize: "1.1rem",
      fontWeight: "500",

      // styles for the search wrapper
    },
  };

  return (
    <div className="w-100 w-md-100 pt-5">
      <div className="d-flex flex-column flex-md-row mb-3 w-100">
        <div className="d-flex flex-column  flex-md-column w-md-50 w-100 me-md-10">
          <label className="form-label fs-6 fw-bolder text-dark required">
            First Name
          </label>
          <input
            placeholder="First Name"
            disabled={isLoading}
            {...getFieldProps("overview.firstName")}
            className={clsx("form-control bg-transparent", {
              "is-invalid":
                touched?.overview?.firstName && errors?.overview?.firstName,
            })}
            type="text"
          />
          {touched?.overview?.firstName && errors?.overview?.firstName && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors?.overview?.firstName}</span>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-column flex-md-column w-md-50 w-100">
          <label className="form-label fs-6 fw-bolder text-dark required">
            Last Name
          </label>
          <input
            type="text"
            autoComplete="off"
            disabled={isLoading}
            placeholder="Last Name"
            {...getFieldProps("overview.lastName")}
            className={clsx("form-control bg-transparent", {
              "is-invalid":
                touched?.overview?.lastName && errors?.overview?.lastName,
            })}
          />
          {touched?.overview?.lastName && errors?.overview?.lastName && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors?.overview?.lastName}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-flex flex-column flex-md-row mb-3 w-100">
        <div className="d-flex flex-column flex-md-column w-md-50 w-100 me-md-10">
          <label className="form-label fs-6 fw-bolder text-dark required">
            Email
          </label>
          <input
            type="email"
            autoComplete="off"
            placeholder="Email"
            disabled={isLoading}
            {...getFieldProps("overview.email")}
            className={clsx("form-control bg-transparent", {
              "is-invalid": touched?.overview?.email && errors?.overview?.email,
            })}
          />
          {touched?.overview?.email && errors?.overview?.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors?.overview?.email}</span>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-column flex-md-column w-md-50 w-100">
          <label className="form-label fs-6 fw-bolder text-dark">
            Contact Number
          </label>
          <input
            type="tel"
            autoComplete="off"
            placeholder="Contact Number"
            disabled={isLoading}
            {...getFieldProps("overview.contact")}
            className={clsx("form-control bg-transparent", {
              "is-invalid":
                touched?.overview?.contact && errors?.overview?.contact,
            })}
          />
          {touched?.overview?.contact && errors?.overview?.contact && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors?.overview?.contact}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}
      {/* begin::Form group */}

      <div className="d-flex flex-column flex-md-row  mb-3 w-100">
        <div className="d-flex flex-column flex-md-column w-md-50 w-100 me-md-10 ">
          <label className="form-label fs-6 fw-bolder text-dark ">
            Department
          </label>
          <Select
            value={values.department}
            name="Select Department"
            onChange={(v: any) => {
              setOptionRole(v);
              setFieldValue("overview.department", v);
            }}
            options={OptionsRole}
          />
        </div>
        <div className="d-flex flex-column flex-md-column w-md-50 w-100">
          <label className="form-label fs-6 fw-bolder text-dark d-flex align-items-center">
            Role{" "}
            <span
              className="cursor-pointer ms-3 btn btn-primary p-1 py-0 d-flex justify-content-center"
              onClick={handleAddRole}
              style={{ width: "25px", height: "25px", fontSize: "17px" }}
            >
              +
            </span>
            {/* <span className="cursor-pointer ms-3" onClick={handleEditRole}>edit</span>
            <span className="cursor-pointer ms-3" onClick={()=> onDelete(values.role.id)}>delete</span> */}
          </label>
          <div>
            <Select
              value={values.role}
              name="Select Role"
              onChange={(v: any) => {
                // setRole(v);
                setSelectedRoleId(v.id);
                setFieldValue("overview.role", v);
              }}
              options={roles}
            />
            <div className="position-relative">
              <span
                className="cursor-pointer ms-3"
                style={{ position: "absolute", top: "-33px", right: "80px" }}
                onClick={handleEditRole}
              >
                <img
                  alt="Edit"
                  src={toAbsoluteUrl("/media/icons/duotune/general/gen055.svg")}
                />
              </span>
              <span
                className="cursor-pointer ms-3"
                style={{ position: "absolute", top: "-33px", right: "50px" }}
                onClick={() => onDelete(values.role.id)}
              >
                <img
                  alt="Delete"
                  src={toAbsoluteUrl("/media/icons/duotune/general/gen064.svg")}
                />
              </span>
            </div>
          </div>
        </div>

        {openModal && (
          <RoleFormModal
            onClose={handleCloseModal}
            isEdit={editRole}
            currentUserId={selectedRoleId}
          />
        )}
        {showPrompt && (
          <ConfirmationPrompt
            title={"Delete Role"}
            message={"Are you sure to delete this role?"}
            onConfirm={deleteRecord}
            onCancel={onCancel}
            isloading={loading}
          />
        )}
      </div>

      <div className="d-flex flex-column flex-md-row mb-3 w-100">
        <div className="d-flex flex-column flex-md-column w-md-50 w-100 me-md-10">
          <label className="form-label fs-6 fw-bolder text-dark">
            Region
          </label>
          <div onClick={() => setFieldTouched("regions")}>
            <Multiselect
              options={allRegions}
              style={style}
              customArrow={<CustomArrow />}
              customCloseIcon={<CustomCrossButton />}
              selectedValues={allRegions?.filter((region: any) =>
                values?.region.includes(region?.id)
              )}
              displayValue={"regionName"}
              placeholder="Select Region"
              onSelect={async (selectedList) => {
                const selectedIds = selectedList.map(
                  (region: any) => region.id
                );
                await setFieldValue("overview.region", selectedIds);
                setFieldTouched("overview.region");
              }}
              onRemove={async (selectedList) => {
                const selectedIds = selectedList.map(
                  (region: any) => region.id
                );
                await setFieldValue("overview.region", selectedIds);
                setFieldTouched("overview.region");
              }}
              selectedValueDecorator={(value, option) => {
                return <CustomTooltip value={value} option={option} />;
              }}
            />
          </div>
          {touched?.overview?.region && errors?.overview?.region && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors?.overview?.region}</span>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-column flex-md-column w-md-50 w-100"></div>
        {/* <div className="d-flex flex-column flex-md-column w-md-50 w-100">
          <label className="form-label fs-6 fw-bolder text-dark d-flex cursor-pointer">
            <input
              className="form-check-input me-3"
              type="checkbox"
              name={"isUserForRetailer"}
              disabled={isSubmitting}
              checked={values?.isUserForRetailer}
              onChange={(e) => {
                setFieldValue("overview.isUserForRetailer", e.target.checked);
              }}
            />
            <h6 className="mb-0 fs-6 fw-bolder">Is Regional Retailer</h6>
          </label>
        </div> */}
      </div>

      {/* <div className="d-flex flex-column flex-md-row  mb-3 w-100">
        <div className="d-flex flex-column flex-md-column w-md-50 w-100 me-md-10 ">
          <label className="form-label fs-6 fw-bolder text-dark required ">
            Retailer List
          </label>
          <div>
            <Multiselect
              options={allRetailers}
              style={style}
              disable={!values?.isUserForRetailer}
              customArrow={<CustomArrow />}
              customCloseIcon={<CustomCrossButton />}
              // selectedValues={allRetailers?.filter(
              //   (region: any) => region?.id == values?.overview?.region
              // )}
              selectedValues={allRetailers.filter((retailer:any)=>retailer._id ==values?.retailerForThisUser)}
              selectionLimit={1}
              displayValue={"retailerName"}
              placeholder="Select Retailer"
              onSelect={async (e) => {
                await setFieldValue("overview.retailerForThisUser", e?.[`0`]?._id);
                setFieldTouched("overview.retailerForThisUser");
              }}
              onRemove={async (e) => {
                await setFieldValue("overview.retailerForThisUser", e);
                setFieldTouched("overview.retailerForThisUser");
              }}
              selectedValueDecorator={(value: string, option: any) => {
                return <CustomTooltip value={value} option={option} />;
              }}
            />
          </div>
          {touched?.overview?.region && errors?.overview?.region && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors?.overview?.region}</span>
              </div>
            </div>
          )}
        </div>
      </div> */}

      {/* begin::Form group */}
    </div>
  );
};

export default Overview;
