/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { PermissionContext } from "../../../../../context/PermissionContext";
import { useAuth } from "../../../../../app/modules/auth";
import { can } from "../../../../../utils/PermissionUtils/PermissionUtils";


const SidebarMenuMain = () => {
  const { currentUser } = useAuth();
  const { isAdmin } = currentUser;

  const menuItems = [
    // {
    //   name: "dashboard",
    //   icon: "art002.svg",
    //   title: "Dashboard",
    //   type: "single",
    //   permissionName: "Dashboard",
    // },
    // {
    //   name: "demo",
    //   icon: "gen007.svg",
    //   title: "Component Demo",
    //   type: "single",
    //   permissionName: "Demo",
    // },
    {
      name: "users",
      icon: "gen049.svg",
      title: "Users & Permissions",
      type: "single",
      permissionName: "Users",
      alwaysShow: isAdmin ? true : false,
    },
    {
      name: "product",
      icon: "gen019.svg",
      title: "Products",
      type: "single",
      permissionName: "Product",
    },
    {
      name: "category",
      icon: "gen011.svg",
      title: "Categories",
      type: "single",
      permissionName: "Category",
    },

    {
      name: "accessory",
      icon: "gen028.svg",
      title: "Manage Accessories",
      type: "sub",
      permissionName: "Accessory",
      subItems: [
        {
          name: "subcategory",
          title: "Subcategories",
          fontIcon: "bi-layers",
          hasBullet: true,
        },
        {
          name: "accessory",
          title: "Accessories",
          fontIcon: "bi-layers",
          hasBullet: true,
        },
      ],
    },
    {
      name: "languages",
      icon: "gen022.svg",
      title: "Manage Languages",
      type: "sub",
      permissionName: "Languages",
      subItems: [
        {
          name: "translations",
          title: "Translations",
          fontIcon: "bi-layers",
          hasBullet: true,
        },
        {
          name: "languages",
          title: "Languages",
          fontIcon: "bi-layers",
          hasBullet: true,
        },
      ],
    },
    {
      name: "region",
      icon: "gen018.svg",
      title: "Regions",
      type: "single",
      permissionName: "Region",
    },

    {
      name: "unit",
      icon: "gen060.svg",
      title: "Units",
      type: "single",
      permissionName: "Unit",
    },
    {
      name: "attribute",
      icon: "gen017.svg",
      title: "Attributes",
      type: "single",
      permissionName: "Attribute",
    },
    {
      name: "quiz",
      icon: "gen022.svg",
      title: "Manage Frontend",
      type: "sub",
      permissionName: "Quiz",
      subItems: [
        {
          name: "quiz",
          title: "Quiz Questions",
          fontIcon: "bi-layers",
          hasBullet: true,
        },
        // {
        //   name: "frontend-demo",
        //   title: "Frontend Demo",
        //   fontIcon: "bi-layers",
        //   hasBullet: true,
        // },
      ],
    },
    {
      name: "retailer",
      icon: "gen051.svg",
      title: "Retailers",
      type: "single",
      permissionName: "Retailer",
    },

    {
      name: "lead",
      icon: "gen051.svg",
      title: "Leads",
      type: "single",
      permissionName: "Lead",
    },

    {
      name: "mappings",
      icon: "gen051.svg",
      title: "Mappings",
      type: "single",
      permissionName: "Mappings",
    },
    {
      name: "scrappings",
      icon: "gen051.svg",
      title: "Scrapings",
      type: "single",
      permissionName: "Scrappings",
    },
    {
      name: "helps",
      icon: "gen051.svg",
      title: "Helps",
      type: "single",
      permissionName: "Helps",
    },
    {
      name: "productcronjobs",
      icon: "gen051.svg",
      title: "Productcronjobs",
      type: "single",
      permissionName: "Productcronjobs",
    },

    // Add other menu items as needed
  ];

  const filteredItems = menuItems.filter(
    (item: any) => 
      item.alwaysShow ||
      can(item.permissionName, "view", currentUser.permissions)
  );


  console.log(filteredItems)

  

  return (
    <>
      {filteredItems.map((item) =>
        item.type === "single" ? (
          <SidebarMenuItem
            key={item.name}
            to={`/${item.name}`}
            icon={`/media/icons/duotune/general/${item.icon}`}
            title={item.title}
            fontIcon="bi-app-indicator"
          />
        ) : (
          <SidebarMenuItemWithSub
            key={item.name}
            to="/"
            fontIcon="bi-archive"
            icon={`/media/icons/duotune/general/${item.icon}`}
            title={item.title}
          >
            {item?.subItems?.map((subItem: any) => (
              <SidebarMenuItem
                key={subItem.name}
                to={`/${subItem.name}`}
                hasBullet={subItem.hasBullet}
                title={subItem.title}
                fontIcon={subItem.fontIcon}
              />
            ))}
          </SidebarMenuItemWithSub>
        )
      )}
    
{/* <SidebarMenuItem
    to="/lead"
    icon='/media/icons/duotune/general/gen051.svg'
    title="Leads"
    fontIcon="bi-app-indicator"
  /> */}

</>
  );
};

export { SidebarMenuMain };
