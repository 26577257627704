import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers";
import { useFormik } from "formik";
import TranslationForm from "./TranslationForm";
import { translationValidationSchema } from "../../validations/translationValidationSchema";
import { toast } from "react-toastify";
import {
  addTranslation,
  getSingleTranslationByGoogle,
  getSingleTranslation,
  getSingleLanguageTranslation,
} from "../../request/translationRequest";
import { getLanguage } from "../../request/languageRequest";
import TranslationSkeleton from "../../../components/Skeletons/TranslationFields";
import {
  TFormikValues,
  TTranslationLanguage,
  TTranslationModalProps,
} from "../../../types/Module/Translations/TranslationFormModal";

const TranslationFormModal = ({
  onClose,
  currentRecord,
}: TTranslationModalProps) => {
  const [loading, setLoading] = useState(false);
  const [autoloading, setautoLoading] = useState(false);
  const [singleAutoLoading, setSingleAutoLoading] = useState(false);
  const [getSingleTranslationLoading, setgetSingleTranslationLoading] =
    useState(false);
  const [translationsforlanguage, setTranslationsforlanguage] =
    useState<TFormikValues>({ labelText: "", temp: [] });
  let title = currentRecord && currentRecord.id ? "Edit" : "Add";

  useEffect(() => {
    const fn = async () => {
      const searchQuery = `page=${1}&limit=${100}`;
      try {
        setgetSingleTranslationLoading(true);
        const res = await getLanguage(searchQuery);
        if (res.data.status) {
          const temp = res.data.data.data.map(
            (language: TTranslationLanguage) => {
              return {
                languageName: language.languageName,
                languageCode: language.languageCode,
                value: "",
                id: language.id,
              };
            }
          );
          formik.setValues({
            ...currentRecord,
            temp,
          });
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network error");
      } finally {
        setgetSingleTranslationLoading(false);
      }
    };

    const fn1 = async () => {
      try {
        setgetSingleTranslationLoading(true);
        const res = await getSingleTranslation(currentRecord.id);
        if (res.data.status) {
          const send = {
            id: res.data.data._id,
            labelText: res.data.data.labelText,
          };
          const temp = res.data.data.translations;

          formik.setValues({
            ...send,
            temp,
          });
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network error");
      } finally {
        setgetSingleTranslationLoading(false);
      }
    };

    !(currentRecord && currentRecord.id) ? fn() : fn1();
  }, []);

  const formik = useFormik<TFormikValues>({
    initialValues: translationsforlanguage,
    validationSchema: translationValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (!autoloading && values.temp.some((item) => !item.value)) {
        // If Generate button is not clicked and some translation fields are empty
        formik.setFieldError("labelText", "All Translations Are Required.");
        setSubmitting(false);
        return;
      }
      setLoading(true);
      const fn = async () => {
        setSubmitting(true);
        try {
          const res = await addTranslation(values);
          if (res.data.status) {
            toast.success("Record saved successfully");
            typeof onClose === "function" && onClose();
          } else {
            toast.error(`${res.data.message}`);
          }
        } catch (error) {
          //TODO: make error when go live
          toast.error("Network error");
        } finally {
          setSubmitting(false);
          setLoading(false);
        }
      };
      fn();
    },
  });

  const handleAuto = useCallback(() => {
    const fn = async () => {
      const checkforEmpty = await formik.setFieldTouched("labelText");
      if (Object.keys({ ...checkforEmpty }).length !== 0) {
        return;
      }

      setautoLoading(true);

      let payload = {
        labelText: formik.values.labelText,
        allLanguages: [],
      };
      const response = await getSingleTranslationByGoogle(payload);

      if (response.data.status) {
        let temp = response.data.data;
        formik.setValues({ ...formik.values, temp });
      } else {
         toast.error(response?.data?.message)
      }

      setautoLoading(false);
    };
    fn();
  }, [formik.values]);

  const handleSingleLangaugeTranslations = useCallback(
    (index: number, modifiedObject: TTranslationLanguage) => {
      const fn = async () => {
        const checkforEmpty = await formik.setFieldTouched("labelText");
        if (Object.keys({ ...checkforEmpty }).length !== 0) {
          return;
        }

        setSingleAutoLoading(true);
        let payload = {
          labelText: formik.values.labelText,
          allLanguages: [
            {
              languageName: modifiedObject && modifiedObject.languageName,
              languageCode: modifiedObject && modifiedObject.languageCode,
              _id: modifiedObject?.id || modifiedObject?.languageId,
            },
          ],
        };
        const response = await getSingleLanguageTranslation(payload);

        if (response.data.status) {
          let translationdata = response.data.data[0];
          let temp = formik.values.temp;
          temp[index] = translationdata;
          formik.setValues({ ...formik.values, temp });
        }
        setSingleAutoLoading(false);
      };
      fn();
    },
    [formik.values]
  );

  return (
    <Modal
      className="modal fade"
      id="kt_modal_select_location"
      data-backdrop="static"
      tabIndex={-1}
      size="xl"
      role="dialog"
      show={true}
      dialogClassName="modal-md modal-dialog-centered"
      aria-hidden="true"
      onHide={onClose}
    >
      {getSingleTranslationLoading ? (
        <>
          <div className="modal-header">
            <h2 className="modal-title fw-bolder">{title} Translations</h2>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              onClick={onClose}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body scroll-y">
            <TranslationSkeleton fields={50} />
          </div>
        </>
      ) : (
        <>
          <div className="modal-header">
            <h2 className="modal-title fw-bolder">{title} Translations</h2>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              onClick={onClose}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body scroll-y">
            <TranslationForm
              {...formik}
              handleAuto={handleAuto}
              autoloading={autoloading}
              handleSingleLangaugeTranslations={
                handleSingleLangaugeTranslations
              }
              singleAutoLoading={singleAutoLoading}
            />
          </div>
        </>
      )}
      <div className="modal-footer d-flex justify-content-end">
        <button type="button" className="btn btn-danger me-4" onClick={onClose}>
          Cancel
        </button>
        <button
          type="button"
          id="kt_password_reset_submit"
          disabled={formik.isSubmitting || !formik.isValid|| autoloading || singleAutoLoading}
          className="btn btn-primary"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {!loading && <span className="indicator-label">Save</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </Modal>
  );
};

export default TranslationFormModal;
