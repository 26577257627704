import ProductcronjobsForm from "../generatedForms/Productcronjobs";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
  addProductcronjobs,
  editProductcronjobs,
  getSingleProductcronjobs,
} from "../generatedRequest/productcronjobs";
import { ProductcronjobsSchema } from "../generatedValidations/ProductcronjobsSchema";
import { useSearchParams } from "react-router-dom";
import FormSkeleton from "../components/Skeleton/FormSkeleton";

type TProductcronjobsModalProps = {
  isEdit: boolean;
};

const ProductcronjobsFormModal = ({
  isEdit,
}: TProductcronjobsModalProps) => {
  const [loading, setLoading] = useState(false);
  const [queryParameters] = useSearchParams();
  const [initload, setInitload] = useState(true);
  const cronJobId = queryParameters.get("q") || "";
  const [initialValues, setInitialValues] = useState({
    cronJobName: "",
    cronJobPurpose: "",
    status: true,
    isRunning: false,
    count: "",
    searchString: "",
    brand:"",
    sortMethod: "updated-desc",
  });

  const handleSubmit = async (payload: any) => {
    setLoading(true);
    try {
      const res = isEdit
        ? await editProductcronjobs(payload, cronJobId)
        : await addProductcronjobs(payload);
      if (res.data.status) {
        isEdit
          ? toast.success("Cron job is edited Successfully")
          : toast.success("Cron job  is added Successfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<any>({
    initialValues: initialValues,
    validationSchema: ProductcronjobsSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    const fetchCronJobData = async () => {
      setLoading(true);
      try {
        const response = await getSingleProductcronjobs(cronJobId); // Call your API to get user data
        if (response.data.status) {
          formik.setValues(response.data.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setLoading(false);
        setInitload(false);
      }
    };

    if (isEdit) {
      fetchCronJobData();
    } else {
      setInitload(false);
    }
  }, [isEdit, cronJobId]);

  if (loading && initload) {
    return <FormSkeleton numberOfInput={8} />;
  }
  return (
    <>
    <ProductcronjobsForm
      {...formik}
      isEdit={isEdit}
      isloading={loading}
    />

    <div className="d-flex justify-content-end">
      <button
        type="button"
        id="kt_password_reset_submit"
        disabled={loading || !formik.isValid}
        className="btn btn-primary"
        onClick={() => formik.handleSubmit()}
      >
        {!loading && <span className="indicator-label">Save</span>}
        {loading && (
          <span className="indicator-progress" style={{ display: "block" }}>
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </button>
    </div>
  </>
  );
};

export default ProductcronjobsFormModal;
