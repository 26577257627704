import React, { useCallback, useState } from "react";
import axios from "axios";
import { useDelete } from "../customHooks/useDelete/useDelete";
import useEdit from "../customHooks/useEdit/useEdit";
import ConfirmationPrompt from "../components/ConfirmationPrompt";
import { toast } from "react-toastify";
import { ActionCells } from "../components/ActionCells";
import { MODULE_DELETE_URL } from "../app/request/userRequest";
import { TModuleDelete } from "../types/Network_ Request/moduleWiseApiType/deleteApi";
import { TTableAction } from "../types/TableActions";
import PermissionPrompt from "../components/PermissionPrompt";
import { usePermissions } from "../utils/Customhooks/permissionHook/usePermissionHook";
import {
  deleteProductcronjobs,
  triggerProductcronjobs,
  updateStatus,
} from "../generatedRequest/productcronjobs";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setRerender } from "../generatedSlice/productcronjobsSlice";
import { useStatus } from "../customHooks/useStatus/useStatus";

const moduleName = "Productcronjobs";

const ProductcronjobsTableAction = ({ productcronjob }: any) => {
  const { rerender1 } = useSelector(
    (state: RootState) => state.productcronjobs
  );
  const { doEdit } = useEdit(moduleName);
  const dispatch = useDispatch();
  const [showPermissionPrompt, setShowPermissionPrompt] =
    useState<boolean>(false);
  const handleAfterDelete = useCallback(
    (flag: boolean, response: any) => {
      if (flag) {
        toast.success("record deleted");
        dispatch(setRerender(!rerender1));
        // do whatever you want with response
      } else {
        toast.error("error");
      }
    },
    [rerender1, dispatch]
  );

  const handleAfterStatus = useCallback(
    (status: boolean, res: any) => {
      if (status) {
        toast.success("status updated successfully");
        dispatch(setRerender(!rerender1));
      } else {
        //TODO: make error when go live
        toast.error(res?.data?.message || "something went wrong" );
      }
    },
    [rerender1, dispatch]
  );

  const {
    onStatus,
    showStatusPrompt,
    statusloading,
    onstatusCancel,
    ChangeStatus,
  } = useStatus(updateStatus, true, handleAfterStatus);

  const triggerjob = async (job: any) => {
    try {
      const response = await triggerProductcronjobs(job);
      if (response.data.status) {
        toast.success(response?.data?.message);
        dispatch(setRerender(!rerender1));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error("Network error");
    }
  };

  const { canDo, currentActivity } = usePermissions();

  const { onDelete, deleteRecord, showPrompt, onCancel,loading } = useDelete(
    deleteProductcronjobs,
    true,
    handleAfterDelete
  );
  const actions = ["Edit", "Delete","Change Status", "Trigger-Job"];

  const handleCancel = useCallback(() => {
    const fn = () => {
      setShowPermissionPrompt(false);
    };
    fn();
  }, []);

  const handleSelect = useCallback(
    (selectedAction: string) => {
      switch (selectedAction) {
        case "Edit":
          // if (canDo(moduleName, "Edit")) {
          doEdit(productcronjob._id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        case "Delete":
          // if (canDo(moduleName, "Delete")) {
          onDelete(productcronjob._id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        case "Trigger-Job":
          // if (canDo(moduleName, "Delete")) {
          triggerjob(productcronjob);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
          case "Change Status":
            onStatus(productcronjob);
            break;
        default:
          break;
      }
    },
    [canDo, doEdit, productcronjob, onStatus, onDelete, triggerjob]
  );
  return (
    <>
      {showPermissionPrompt && (
        <PermissionPrompt
          title={"Access Denied"}
          image="/media/auth/permission-error-light.svg"
          message={currentActivity ? currentActivity : ""}
          onCancel={handleCancel}
          moduleName={moduleName}
        />
      )}
      {showPrompt && (
        <ConfirmationPrompt
          title={"Delete Record"}
          message={"Are you sure to delete this record?"}
          onConfirm={deleteRecord}
          onCancel={onCancel}
          isloading={loading}
        />
      )}
      {showStatusPrompt && (
        <ConfirmationPrompt
          title="Change Status"
          message="Are you sure you want to change the status ?"
          onCancel={onstatusCancel}
          onConfirm={ChangeStatus}
          isloading={statusloading}
        />
      )}
      <ActionCells actions={actions} onSelect={handleSelect} />
    </>
  );
};
export default ProductcronjobsTableAction;
