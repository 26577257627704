import React from "react";
import ProductcronjobsFormModal from "../../generatedFormModal/ProductcronjobsFormModal";

const initialValues = { name: "t1o4n" };

const EditProductcronjobs = ({ currentRecord }: { currentRecord?: any }) => {
  return (
    <div>
      <ProductcronjobsFormModal isEdit={true} />
    </div>
  );
};

export default EditProductcronjobs;
