import * as Yup from 'yup';

export const ProductcronjobsSchema = Yup.object().shape({
  cronJobName: Yup.string()
    .required('Cron job name is required.'),

  cronJobPurpose: Yup.string()
    .required('Cron job purpose is required.'),

  status: Yup.boolean()
    .required('Status is required.')
    .oneOf([true, false], 'Status must be a boolean value.'),

  isRunning: Yup.boolean()
    .required('isRunning flag is required.')
    .oneOf([true, false], 'isRunning must be a boolean value.'),

    count: Yup.number()
    .required('Count is required.')
    .typeError('Count must be a number.')
    .max(200, 'Count must not be greater than 200'),

  searchString: Yup.string()
    .required('Search Term is required.'),

  sortMethod: Yup.string()
    .required('Sort method is required.'),
});
