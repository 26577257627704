import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const PRODUCTCRONJOBS_GET_URL = BASE_URL + "/productcronjob";

const PRODUCTCRONJOBS_DELETE_URL = BASE_URL + "/productcronjob/";
const PRODUCTCRONJOBS_ADD_URL = BASE_URL + "/productcronjob/";
const PRODUCTCRONJOBS_EDIT_URL = BASE_URL + "/productcronjob/";

const PRODUCTCRONJOBS_TRIGGER_URL =
  BASE_URL + "/productcronjob/trigger-cron-laptop";

const PRODUCTCRONJOBS_TRIGGER_URL_MONITOR =
  BASE_URL + "/productcronjob/trigger-cron-monitor";

  const PRODUCTCRONJOBS_TRIGGER_URL_TABLET =
  BASE_URL + "/productcronjob/trigger-cron-tablet";

export const getProductcronjobs = (searchParams?: string) => {
  return axios.get(PRODUCTCRONJOBS_GET_URL + "?" + searchParams);
};

export const getSingleProductcronjobs = (id?: string) => {
  return axios.get(PRODUCTCRONJOBS_GET_URL + "/" + id);
};

export const deleteProductcronjobs = (Id: string) => {
  return axios.delete(PRODUCTCRONJOBS_DELETE_URL + Id);
};

export const addProductcronjobs = (payload: any) => {
  return axios.post(PRODUCTCRONJOBS_ADD_URL, payload);
};

export const editProductcronjobs = (payload: any, Id: string) => {
  return axios.patch(PRODUCTCRONJOBS_EDIT_URL + Id, payload);
};

export const triggerProductcronjobs = (payload: any) => {
  let url = "";
  switch (payload.cronJobPurpose) {
    case "Laptop":
      url = PRODUCTCRONJOBS_TRIGGER_URL;
      break;
    case "Computer Monitor":
      url = PRODUCTCRONJOBS_TRIGGER_URL_MONITOR;
      break;
    case "Tablet":
      url = PRODUCTCRONJOBS_TRIGGER_URL_TABLET;
      break;

    default:
      break;
  }
  return axios.post(url, payload);
};

export const updateStatus = (productcronJob: any) => {
  const url = PRODUCTCRONJOBS_EDIT_URL + productcronJob._id;
  const newStatus = productcronJob.status ? false : true;
  const payload = {
    status: newStatus,
  };

  if (productcronJob._id) {
    return axios.patch(url, payload);
  }
};
