import { useState, useEffect } from "react";
import { Marker } from "react-map-gl";
import ReactMapGL from "react-map-gl";
import "./regionMap.css";
import "maplibre-gl/dist/maplibre-gl.css";
import {
  TCoordinates,
  TMapCoordinates,
  TMarkerCoordinates,
} from "../../types/Module/Region/Region";
import { toast } from "react-toastify";

export const CustomMap = ({
  onMapClick,
  initialCoordinates,
  onMarkerClick,
  isEditMode,
}: {
  onMapClick: (coordinates: TMapCoordinates, index: number) => void;
  onMarkerClick: (index: number) => void;
  initialCoordinates?: TMapCoordinates[];
  isEditMode?: boolean;
}) => {
  const [viewState, setViewState] = useState<TMapCoordinates>({
    longitude: 0,
    latitude: 0,
  });
  const [markers, setMarkers] = useState<TMarkerCoordinates[]>([
    {
      longitude: 0,
      latitude: 0,
    },
  ]);

  // Set initial coordinates when the component mounts
  useEffect(() => {
    if (initialCoordinates && initialCoordinates.length > 0) {
      const initialZoom = 2;
      let tempCoordinatesForView = initialCoordinates.map(
        (coordinate: TMapCoordinates) => {
          return {
            longitude: Number(coordinate?.longitude),
            latitude: Number(coordinate?.latitude),
            zoom: initialZoom,
          };
        }
      );
      setViewState(tempCoordinatesForView[tempCoordinatesForView.length - 1]);
      let tempCoordinatesForMarkers = initialCoordinates.map(
        (coordinate: TMapCoordinates) => {
          return {
            longitude: Number(coordinate?.longitude),
            latitude: Number(coordinate?.latitude),
          };
        }
      );
      setMarkers(tempCoordinatesForMarkers);
    }
  }, [initialCoordinates, isEditMode]);

  const handleMapClick = (event: any) => {
    const coordinates = {
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    };

    let tempAllMarkers = [...markers];
    tempAllMarkers.push(coordinates);
    setMarkers(tempAllMarkers);

    const latitudeString = coordinates.latitude.toString();
    const longitudeString = coordinates.longitude.toString();

    onMapClick(
      {
        latitude: latitudeString,
        longitude: longitudeString,
      },
      markers.length
    );
  };

  const handleMarkerClick = (index: number) => {
    if (index >= 0 && index < markers.length) {
      let currentMarkers = [...markers];
      currentMarkers.splice(index, 1);
      setMarkers(currentMarkers);
    } else {
      console.log("here");
      toast.error("Something went wrong");
    }

    onMarkerClick(index);
  };

  return (
    <div style={{ height: "500px" }}>
      <ReactMapGL
        cursor="pointer"
        mapboxAccessToken="pk.eyJ1IjoiZXJuZWJ1dGEiLCJhIjoiY2s2bDVwYTRlMGFwdDNncGE0ZWdjaWZzMCJ9.2PppNmYQsYZ8HDSjEGwjCA"
        onClick={handleMapClick}
        mapStyle="mapbox://styles/ernebuta/ck6l5q6me1dmn1ip74713pndm"
      >
        {/* Marker */}
        {markers?.map((marker, index) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleMarkerClick(index);
            }}
          >
            <Marker
              key={index}
              longitude={marker.longitude}
              latitude={marker.latitude}
              offset={[0, 0]}
            >
              <img src="https://img.icons8.com/color/48/000000/marker.png" />
            </Marker>
          </div>
        ))}
      </ReactMapGL>
    </div>
  );
};
